<template>
  <div>
    <!-- showcase -->
    <div class="showcase-xs">
      <h1>My Products</h1>
    </div>
    <div class="wrapper">
      <!-- section dashboard -->
      <section
        class="section jpadding jpadding-20 section-unfixed"
        style="background: #1193f5"
      >
        <div class="jcard jcard-main jcard-nohover">
          <table class="table">
            <thead>
              <tr>
                <th>#</th>
                <th scope="col">Type</th>
                <th scope="col">Name</th>
                <th scope="col">Billed</th>
                <th scope="col">Expiry</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(subscription, index) in subscriptionsList"
                :key="subscription.id"
              >
                <td>{{ subscription.id }}</td>
                <td>{{ subTypeList[subscription.aacType] }}</td>
                <td>Talkii {{ subscription.planName }}</td>
                <td>{{ subscription.duration }}</td>
                <td>{{ getFormatDate(subscription.expiry) }}</td>
                <td>
                  <span class="badge badge-success text-uppercase">{{
                    subStatusList[subscription.status]
                  }}</span>
                </td>
                <td>
                  <button
                    class="jbtn jbtn-icon jbtn-blue"
                    @click="openSubscription(index)"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <section class="separator separator-lux jpadding">
      <img src="@/assets/logos/made-in-lux.svg" alt="Made in Luxembourg" />
    </section>
  </div>
</template>

<script>
import firebase from "firebase/app";
// import C from "@/constants";
import PLANS from "@/plans";
import axios from "axios";

export default {
  name: "DashboardSingle",
  data() {
    return {
      subStatusList: PLANS.STATUS_INDEX,
      subTypeList: PLANS.TYPE_INDEX,
      firstname: "",
      lastname: "",
      homeTitle: "Bonjour ",

      //userId: firebase.auth().currentUser.uid,
      subscriptionsList: [
        PLANS.DEFAULT_SUB
      ],
    };
  },
  methods: {
    // startContrat(type) {
    //   this.$store.commit("startAddContrat");
    // },
    allSubscriptions: function() {
      const id = firebase.auth().currentUser.uid;
      console.log(id);
      // if (this.userId != "") {
      axios
        .post("/api/functions.php", {
          request: 5,
          userId: id,
        })
        .then((response) => {
          console.log(response.data);
          this.subscriptionsList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openSubscription(index) {
      this.$store.commit("setSubscription", this.subscriptionsList[index]);
      this.$router.push({
        name: "Subscription",
        params: { id: this.subscriptionsList[index].id },
      });
    },
    getFormatDate(date){
      console.log(date);
    }
  },
  mounted() {
    this.allSubscriptions();
  },
};
</script>

<style scoped></style>
